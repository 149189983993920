.discount-bubble {
    .discount-label {
        color: $button-primary-color;
    }
}

.prodpage-discount-bubble {
    .prodpage-discount-label {
        color: $button-primary-color;
    }
}
